html,
body {
  height: 100%;
}


body,
p,
a,
h1,
h2,
h3,
h4,
h5,
a,
* {
  font-family: "Poppins";
}

p {
  font-size: 16px;
}

@media(max-width: 768px) {
  .bg-cover {
    background-size: cover !important;
  }

  .d-xs-none {
    display: none !important;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #ccc;
}

.bg-gray {
  background-color: #eee;
}

.badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 16px;
  gap: 4px;
  background: #cae3f9;
  border-radius: 30px;
  width: 112px;
  height: 23px;
  color: black !important;
}

.text-theme-2 {
  color: #FFD600;
}

/* Your existing styles here */

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .main-menu {
    display: none;
  }

  .navbar-toggler {
    display: block;
  }

  .sidebar {
    display: block;
  }
}

/* Media query for larger screens */
@media screen and (min-width: 769px) {
  .sidebar {
    display: none;
  }

  .main-menu.open {
    display: block;
  }
}